import { mapGetters, mapActions } from 'vuex'
import { showVueToast } from '@/utils'
import NotFound from '@/components/illustration/404'
import Check from '@/components/icons/Check'
import Cancel from '@/components/icons/Cancel'
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
export default {
  name: 'prakerja-test-form',
  components: {
    NotFound,
    Check,
    Cancel,
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    CountdownExercise: () => import(/* webpackChunkName: "countdown" */ '@/components/countdown/CountdownExercise'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  data() {
    return {
      selectedAnswer: [],
      selectedAnswerSurvey: [],
      isFetching: true,
      examAvailable: false,
      errors: [],
      questionData: null,
      showModal: false,
      perPage: 24,
      currentPage: 0,
      visibleBackToTop: false,
      visibleExerciseNav: false,
      isExpand: true,
      isFinishAnswerVisible: false,
      activeState: false,
      currentElementOnViewIndex: 1,
      isLast: false,
      isFirst: false,
      questActive: 1
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('prakerja', ['examData', 'examReference', 'examDataNonWebinar', 'examDataNonWebinarReference']),
    ...mapGetters('common', ['isMobile']),
    isExamReference() {
      return this.$route.path.includes('reference')
    },
    examType() {
      return this.$route.params.examType
    },
    classId() {
      return this.$route.params.id
    },
    exerciseId() {
      return this.$route.params.exerciseId
    },
    isNew() {
      return this.$route.meta.new
    },
    answeredQuestionCount() {
      let count = 0
      this.selectedAnswer.forEach((item) => {
        if (item && item.answer) count++
      })
      return count
    },
    questionNavList() {
      return this.paginate(this.questionData?.exercise_detail || [], this.perPage)
    },
    correctAnswerCount() {
      let count = 0
      this.questionData?.exercise_detail.forEach((item) => {
        if (item.last_result === true) count++
      })
      return count
    },
    selectedActivityId() {
      return this.$route.query.activityId
    }
  },
  created() {
    this.getData()
    window.addEventListener('scroll', this.handleScroll)
    if (this.isMobile) this.visibleExerciseNav = false
    if (this.isMobile) this.isExpand = false
    this.isFirst = true
    this.saveActivityIdToLocalStorage()
    // TODO: check if need redemption code
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['getExamData', 'getExamDataReference', 'submitExam', 'getExamDataNonWebinar', 'updateExamDataNonWebinar', 'submitExamNonWebinar', 'getExamDataNonWebinarReference']),
    saveActivityIdToLocalStorage() {
      if (this.selectedActivityId) localStorage.setItem('activityId', this.selectedActivityId)
    },
    toggleExerciseNav() {
      this.visibleExerciseNav = !this.visibleExerciseNav
    },
    handleScroll() {
      if (window.pageYOffset > 100) {
        this.visibleBackToTop = true
      } else {
        this.visibleBackToTop = false
      }
    },
    goToTop() {
      window.scrollTo(0, 0)
      this.currentElementOnViewIndex = 1
    },

    getExpand() {
      this.activeState = !this.activeState
      this.isExpand = !this.isExpand
    },
    paginate(arr, size) {
      return arr.reduce((acc, val, i) => {
        const idx = Math.floor(i / size)
        const page = acc[idx] || (acc[idx] = [])
        page.push(val)

        return acc
      }, [])
    },
    forConvert(content) {
      if (!content) return
      const isTrue = content.substring(3, 12)
      if (isTrue === 'withImage') {
        let fix = ''
        let forHtml = '<p>'
        const front = content.slice(12)
        fix = front.slice(0, -4)
        const str = JSON.stringify(fix)
        const str2 = `${JSON.parse(str)}`
        const str3 = JSON.parse(str2)
        for (let i = 0; i < str3.length; i++) {
          if (i === str3.length - 1) {
            str3[i].substring(0, 3) === 'img' ? (forHtml += `<img src='${str3[i].slice(3)}' /></p>`) : (forHtml += `${str3[i].slice(3)}</p>`)
          } else {
            str3[i].substring(0, 3) === 'img' ? (forHtml += `<img src='${str3[i].slice(3)}' />`) : (forHtml += `${str3[i].slice(3)}`)
          }
        }
        return forHtml
      } else {
        return content
      }
    },
    getData() {
      if (this.isExamReference) {
        this.getReference()
        return
      }
      this.getExam()
    },
    getReference() {
      this.showLoading()
      if (this.isNew) {
        this.getExamDataNonWebinarReference({
          studentLmsClassId: this.classId,
          activityItemId: this.exerciseId
        })
          .then((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = true
            res.exercise_detail &&
              res.exercise_detail.forEach((element) => {
                this.selectedAnswer.push(null)
                this.errors.push(false)
              })
            this.questionData = res
          })
          .catch((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = false
          })
      } else {
        this.getExamDataReference({
          classId: this.classId,
          examType: this.examType
        })
          .then((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = true
            res.exercise_detail &&
              res.exercise_detail.forEach((element) => {
                this.selectedAnswer.push(null)
                this.errors.push(false)
              })
            this.questionData = res
          })
          .catch((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = false
          })
      }
    },
    getExam() {
      this.showLoading()
      if (this.isNew) {
        this.getExamDataNonWebinar({
          studentLmsClassId: this.classId,
          activityItemId: this.exerciseId
        })
          .then((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = true
            const answerCollection = []

            res.exercise_detail &&
              res.exercise_detail.forEach((question, i) => {
                this.selectedAnswer.push(null)
                answerCollection.push(null)
                this.errors.push(false)
                const switches = {}
                for (let j = 0; j < 26; j++) {
                  const alphabet = (j + 10).toString(36)
                  if (!question[alphabet]) break
                  switches[question[alphabet]] = alphabet
                }
                const answer = switches[question.last_option_answer]
                answerCollection[i] = {
                  id: question.id,
                  answer
                }
              })
            // const localAnswer = localStorage.getItem(this.exerciseId)
            const localAnswer = null
            if (localAnswer) {
              const localAnswerList = JSON.parse(localAnswer)
              const questionListOrder = answerCollection.map((item) => item.id)
              localAnswerList.sort(function (a, b) {
                return questionListOrder.indexOf(a.id) - questionListOrder.indexOf(b.id)
              })
              // localStorage.setItem(this.exerciseId, JSON.stringify(localAnswerList))
              this.selectedAnswer = localAnswerList
            } else {
              // this.selectedAnswer = answerCollection
              // localStorage.setItem(this.exerciseId, JSON.stringify(this.selectedAnswer))
            }

            this.questionData = res
            this.showModal = dayjs(new Date()).isAfter(dayjs(res.max_submitted_time))
          })
          .catch((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = false
          })
      } else {
        this.getExamData({
          classId: this.classId,
          examType: this.examType
        })
          .then((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = true
            res.exercise_detail &&
              res.exercise_detail.forEach((element) => {
                this.selectedAnswer.push(null)
                this.errors.push(false)
              })
            this.questionData = res
          })
          .catch((res) => {
            this.hideLoading()
            this.isFetching = false
            this.examAvailable = false
          })
      }
    },
    updateAnswerTextArea(selectedAnswer) {},
    updateAnswer(selectedAnswer, questionIndex) {
      this.currentElementOnViewIndex = questionIndex
      if (this.questionData.exercise_type_id === 5) return
      if (dayjs(new Date()).isAfter(dayjs(this.questionData.max_submitted_time))) {
        return showVueToast('Waktu pengerjaan telah habis!', 'error', 3000)
      }

      if (this.exerciseId !== undefined) {
        // localStorage.setItem(this.exerciseId, JSON.stringify(this.selectedAnswer))
        // this.updateExamDataNonWebinar({
        //   studentLmsClassId: this.classId,
        //   activityItemId: this.exerciseId,
        //   payloads: selectedAnswer
        // })
        //   .then((res) => {
        //     if (res.code === 200) {
        //       showVueToast('Jawaban anda berhasil disimpan!', 'success', 1000)
        //       // this.getData()
        //     }
        //   })
        //   .catch((res) => {
        //     if (res.code === 422) {
        //       showVueToast('Sesi kegiatan siswa telah berakhir!', 'error', 3000)
        //     }
        //   })
      }
    },
    async submit() {
      let answer = []
      if (this.questionData.exercise_type_id === 5) {
        answer = this.selectedAnswer
          .filter((value) => value?.answer)
          .map((value) => {
            return { ...value, answer: value?.answer?.replace('<p>', '')?.replace('</p>', '') }
          })
      } else {
        for (let i = 0; i < this.selectedAnswer.length; i++) {
          if (this.selectedAnswer[i]?.answer) await answer.push(this.selectedAnswer[i])
        }
      }

      this.showLoading()
      if (this.isNew) {
        this.submitExamNonWebinar({
          studentLmsClassId: this.classId,
          activityItemId: this.exerciseId,
          payloads: {
            answer
          }
        }).then(() => {
          showVueToast('Jawaban anda berhasil dikirim!', 'success', 3000)
          setTimeout(() => this.goPrevPages(), 3000)
        })
      } else {
        this.submitExam({
          classId: this.classId,
          examType: this.examType,
          payloads: {
            answer
          }
        }).then(() => {
          showVueToast('Jawaban anda berhasil dikirim!', 'success', 3000)
          setTimeout(() => this.$router.push(`/my-class/${this.classId}`), 3000)
        })
      }
    },
    goPrevPages() {
      this.$router.go(-1)
    },
    getElementY(query) {
      return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
    },
    toggleFinishAnswerModal() {
      this.isFinishAnswerVisible = !this.isFinishAnswerVisible
    },
    goPrevNav() {
      this.currentPage--
    },
    goNextNav() {
      this.currentPage++
    },
    prevQuest(active, first, last) {
      this.questActive = active - 1
      this.isLast = false
      this.doScrolling(this.questActive, `#question-${this.questActive}`, 500, first, this.isLast)
    },
    nextQuest(active, first, last) {
      this.questActive = active + 1
      this.isFirst = false
      this.doScrolling(this.questActive, `#question-${this.questActive}`, 500, this.isFirst, last)
    },
    doScrolling(active, element, duration, isFirst, isLast) {
      this.questActive = active
      this.isFirst = isFirst
      this.isLast = isLast
      var startingY = window.pageYOffset
      var elementY = this.getElementY(element)
      this.currentElementOnViewIndex = parseInt(element.split('-')[1])
      var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
      var diff = targetY - startingY - 80
      var easing = function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
      }
      var start

      if (!diff) return
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp
        var time = timestamp - start
        var percent = Math.min(time / duration, 1)
        percent = easing(percent)
        window.scrollTo(0, startingY + diff * percent)
        if (time < duration) {
          window.requestAnimationFrame(step)
        }
      })
    }
  }
}
